import { Sidebar } from "@medm-ui/sidebar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { lastVisitedPage } from "services/last-visited-page/last-visited-page-helper";
import { getPermission } from "services/permission/permission-helper";
import "./sideNav.css";

export function SideNav() {
  const menu = useAppSelector((state) => state.menu.menu);
  const permission = useAppSelector((state) => state.permission.permission);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const [sideNav, setSideNav] = useState([]);
  const navigate = useNavigate();

  const [favourites, SetFavourites] = useState([]);
  let favCopy = [...favourites];

  const saveFavourites = (data: any) => {
    SetFavourites(data);
    favCopy = [...data];
  };
  useEffect(() => {
    const setMenu = () => {
      const data = new Promise((resolve, reject) => {
        let sideData: any[] = [
          {
            icon: "home",
            label: "Home",
            path: "/",
            current: true,
          },
        ];
        if (menu) {
          menu.forEach((x: any) => {
            let data = null;
            //If product is having only one menu icon to display, then only the product name must be displayed.
            if (x.items.length === 1) {
              if (getPermission(x.items[0], permission))
                data = {
                  icon: x.iconClass,
                  label: x.label,
                  path: x.items[0].path,
                };
            } else {
              data = {
                icon: x.iconClass,
                label: x.label,
                childNavItems: fetchChildItems(x.items),
              };
            }
            if (data && data.childNavItems?.length !== 0)
              sideData = [...sideData, data];
          });
          resolve(sideData);
        }
      });
      data.then((res: any) => {
        setSideNav(res);
      });
    };

    let favItems: any = JSON.parse(localStorage.getItem("favourites"));

    function fetchChildItems(item: any) {
      const childData: any = [];
      item.forEach((x: any) => {
        const d: any = {
          icon: x.iconClass,
          label: x.label,
          path: x.path,
        };
        if (x.items) {
          const childItem = fetchChildItems(x.items);
          if (childItem.length) {
            d.childNavItems = childItem;
          }
        }
        let hasPermssion = false;
        if (x.permissions) {
          hasPermssion = getPermission(x, permission);
        }
        if (!x.permissions || hasPermssion) {
          if (
            d.path !== undefined ||
            (d.path === undefined && d.childNavItems?.length)
          ) {
            childData.push(d);
          }
        } else {
          if (favItems) {
            const index = favItems.findIndex(
              (element: any) => element.path === x.path
            );
            if (index !== -1) {
              favItems.splice(index, 1);
            }
          }
        }
      });
      return childData;
    }
    setMenu();

    if (favItems) {
      if (menu) {
        favItems = favItems.filter(
          (item: any) =>
            menu.findIndex(
              (x: any) => x.productKey === item.path.split("/")[1]
            ) !== -1
        );
      }
      saveFavourites(favItems);
    }
  }, [menu, permission]);

  const toggleStyle = {
    height: "50px",
  };

  return (
    <>
      <div className="side-nav">
        <Sidebar
          id="side-nav"
          navItems={sideNav}
          favouritesNavItems={favourites}
          toggleStyle={toggleStyle}
          onNavigate={(data: any) => {
            if (data.path) {
              if (data.path === "/") {
                navigate("/");
              } else {
                const route = routeMap.find((x: any) =>
                  x.path.match(data.path)
                );

                if (route.popout) {
                  const prod = unionConfig.manifest.find(
                    (x: { key: any }) => x.key === route.productKey
                  );
                  lastVisitedPage(prod, unionConfig, route.path)
                  if (route.popout === true) {
                    window.open(route.path, "_blank");
                  } else {
                    window.open(
                      route.path,
                      route.popout.target,
                      route.popout.features
                    );
                  }
                } else {
                  navigate(route.path);
                }
              }
            }
          }}
          onFavouritesNavItems={(data: any, isARemoval: boolean) => {
            if (data) {
              let savedFavourites = JSON.parse(
                localStorage.getItem("favourites")
                  ? localStorage.getItem("favourites")
                  : "[]"
              );
              let removedItem: any[];
              if (isARemoval) {
                removedItem = favCopy.filter(
                  (item: any) =>
                    data.findIndex((x: any) => item.path === x.path) === -1
                );
              }
              if (savedFavourites.length > 0) {
                savedFavourites = savedFavourites.filter(
                  (item: any) =>
                    data.findIndex((x: any) => item.path === x.path) === -1
                );
                if (removedItem) {
                  savedFavourites = savedFavourites.filter(
                    (item: any) =>
                      removedItem.findIndex(
                        (x: any) => item.path === x.path
                      ) === -1
                  );
                }
              }
              localStorage.setItem(
                "favourites",
                JSON.stringify(data.concat(savedFavourites))
              );
              saveFavourites(data);
            }
          }}
          sidebarOpenWidth={276}
        ></Sidebar>
      </div>
    </>
  );
}
