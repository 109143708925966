import { EventHub } from "event";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { PermissionSet, ProductRouteData } from "models";
import { useEffect, useRef, useState } from "react";
import { UnionNotification } from "components/shared/notification";
import { useNavigate, useLocation } from "react-router-dom";
import { UnionLoader } from "components/shared/loader";
import { UnionModal } from "components/shared/modal";
import { ButtonProps } from "@medm-ui/button";
import { getContentPath, getLocalPath, getRouteByLocalPath } from "services/router-helper";
import { PathHelper } from "services/path-helper";
import { parsePermissionResponse } from "services/permission/permission.service";
import { setPermission } from "redux/features/permission";
import "./app-viewer.css";
import { lastVisitedPage } from "services/last-visited-page/last-visited-page-helper";

export function AppViewer(props: any) {
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const unionAuth = useAppSelector((state) => state.auth.authToken);
  const permission = useAppSelector((state) => state.permission.permission);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [showNotificationBar, setshowNotificationBar] = useState(false);
  const [showLoader, setshowLoader] = useState(false);
  const [showModal, setshowModal] = useState(false);

  const showNotificationHandler = (x: any) => {
    setshowNotificationBar(x);
  };
  const showNotificationSnackBar = () => showNotificationHandler(true);
  const hideNotification = () => showNotificationHandler(false);

  const showModalDialog = (x: any) => {
    setshowModal(x);
  };
  const showModalD = () => showModalDialog(true);
  const hideModalD = () => showModalDialog(false);

  const act = [
    {
      buttonId: "Cancel",
      buttonLabel: "Cancel",
      buttonType: "Subtle",
      disabled: false,
      onButtonClick: () => {
        hideModalD();
      },
    },
  ] as ButtonProps[];

  const [notificationDetails, setnotificationDetails] = useState([
    {
      title: null,
      subTitle: null,
      type: null,
      onClose: { hideNotification },
    },
  ]);

  const [loaderDetails, setloaderDetails] = useState([
    { id: null, subtle: false },
  ]);
  const [modalDetails, setmodalDetails] = useState([
    {
      id: null,
      title: null,
      size: "small",
      actions: null,
      onClose: null,
      // background: "#000",
      // opacity: 0.5,
      // zindex: 8000,
      // "z-index":0
    },
  ]);
  const defaultBackdrop = {
    background: "#000",
    opacity: 0.5,
    zindex: 8000,
  };
  function onBackdropToggle(togglingOn: boolean, options?: any) {
    if (togglingOn) {
      // setmodalDetails([{
      //   background: options?.background || defaultBackdrop.background,
      //   opacity: options?.opacity || defaultBackdrop.opacity,
      //   "z-index": defaultBackdrop.zindex
      // }]);
    } else {
      setmodalDetails([null]);
    }
  }
  const [src, setSrc] = useState(null);
  let isCrossDomain: boolean;
  let baseSite: string;
  let unsafeSrc: string;
  const [product, setProduct] = useState(null);
  let eventHub: EventHub;

  useEffect(() => {
    if ((window as { [key: string]: any })["eventHub"]) {
      const accessToken = sessionStorage['accessToken'];
      (window as { [key: string]: any })["eventHub"].emit('accessToken', accessToken);
    }
  }, [unionAuth])

  useEffect(() => {
    setshowLoader(true);

    const prod = unionConfig.manifest.find(
      (x: { key: any }) => x.key === props.props.productKey
    );
    if (product?.key !== prod?.key) {
      setProduct(prod);
    }

    const contentPath = getContentPath(pathname, routeMap, prod?.key);
    updatePath(prod, contentPath);
    setActiveProduct(prod);
    lastVisitedPage(prod, unionConfig, pathname);
  }, [pathname]);

  function updatePath(prod: any, relativePath: string) {
    unsafeSrc = `${prod.environment.appRootUrl}/${relativePath}`;
    const productSrc = `${prod.environment.appRootUrl}/${relativePath}`;
    isCrossDomain = PathHelper.getBaseSite(prod.environment.appRootUrl) !== baseSite;
    if (unionConfig.debugMode) {
      console.log("Loading iframe:", productSrc);
    }
    setSrc(productSrc);
  }

  function setActiveProduct(prod: any) {
    let activeProduct = JSON.parse(localStorage.getItem("activeProduct"));
    if (activeProduct === null) {
      activeProduct = [];
    }
    if (!activeProduct.includes(prod.name)) {
      activeProduct.push(prod.name);
      localStorage["activeProduct"] = JSON.stringify(activeProduct);
    }
  }

  function onIFrameLoaded(iframe: any) {
    if (
      iframe.contentDocument &&
      iframe.contentDocument.URL === "about:blank"
    ) {
      return;
    }
    const elmt = document.getElementById("appFrame");
    wireEventHub(elmt);
  }

  function onConnected(url: string) {
    checkForRedirect(url);
    setshowLoader(false);
    window.dispatchEvent(
      new CustomEvent("eventHub_ready", {
        bubbles: true,
        detail: eventHub,
      })
    );
    console.log(`inside on connected ${eventHub}`);
    const accessToken = sessionStorage['accessToken'];
    eventHub.emit('accessToken', accessToken);
    eventHub.emit("locales", "testing-locales");
    eventHub.emit("session", "testing-session");
    const lastActiveProduct: any = JSON.parse(localStorage.getItem('lastActiveProduct'));
    if (lastActiveProduct?.includes(product?.name)) {
      localStorage.setItem('lastActiveProduct', JSON.stringify(lastActiveProduct.filter((obj: any) => obj !== product.name)));
      eventHub.emit('clearPreviousSession', sessionStorage['union-access-token']);
    }
  }

  function onIframeNavigation(url: string, popup?: boolean, target?: string, features?: string, replace?: boolean) {
    if (!PathHelper.isAbsolutePath(url)) {
      url = PathHelper.getAbsolutePath(url, src);
    }

    if (!url.startsWith(product.environment.appRootUrl)) {
      const pathRelativeToLocalBase = PathHelper.getRelativePath(url, baseSite);
      const localRoute = getRouteByLocalPath<ProductRouteData>(pathRelativeToLocalBase, routeMap);
      if (localRoute) {
        const popout = localRoute.data && localRoute.data.popout;
        if (popout) {
          if (typeof popout === "object") {
            window.open(pathRelativeToLocalBase, popout.target, popout.features);
          } else {
            window.open(pathRelativeToLocalBase, target, features);
          }
        } else {
          navigate("/" + pathRelativeToLocalBase);
        }
        return;
      }

      console.log("Url not allowed: " + url);
      if (popup) {
        window.open(url, target, features);
      } else {
        window.location.href = url;
      }
      return;
    }

    const pathRelativeToBase = PathHelper.getRelativePath(url, product.environment.appRootUrl);
    const localPath = getLocalPath(pathRelativeToBase, routeMap, product.key);
    if (localPath) {
      const route = getRouteByLocalPath<ProductRouteData>(localPath, routeMap);
      const popout = route.popout;//.data && route.data.popout;
      if (popout) {
        if (typeof popout === "object") {
          window.open(route.path, popout.target, popout.features);
        } else {
          window.open(route.path, target, features);
        }
      } else {
        navigate("/" + localPath);
      }
    } else {
      console.error("Unrecognized: " + pathRelativeToBase);
      if (popup) {
        window.open(url, target, features);
      } else {
        updatePath(product, pathRelativeToBase);
      }
    }
  }

  function wireEventHub(iframe: Element) {
    eventHub = new EventHub(
      iframe,
      product.environment.appRootUrl,
      unionConfig.debugMode
    );


    (window as { [key: string]: any })["eventHub"] = eventHub;

    eventHub.subscribe("connected", (x) => {
      console.log(`connected triggered payload is ${x.payload}`);
      onConnected(x.payload);
    });

    eventHub.subscribe("navigate", (x) =>
      onIframeNavigation(x.payload)
    );

    eventHub.subscribe("window.open", (x) => {
      onIframeNavigation(x.payload.url, true, x.payload.target, x.payload.features, x.payload.replace)
    });

    eventHub.subscribe("window.close", () => {
      window.close();
    });

    eventHub.subscribe("window.print", () => {
      window.print();
    });

    eventHub.subscribe("history.back", (x) => {
      console.log("history.back called", x);
      navigate(-1);
    });

    eventHub.subscribe("history.forward", (x) => navigate(1));

    eventHub.subscribe("logout", (x) => {
      navigate("/logout");
    });

    eventHub.subscribe("history.go", (x) => {
      if (x.payload === 1) {
        navigate(1);
      } else if (x.payload === -1) {
        navigate(-1);
      } else {
        window.history.go(x.payload);
      }
    });

    eventHub.subscribe("history.pushState", (x) => {
      const localPath = getLocalPath(x.payload, routeMap, product.key);
      if (localPath) {
        navigate("/" + localPath);
      } else {
        console.error("localPath not found: " + x.payload);
      }
    });

    eventHub.subscribe("history.replaceState", (x) => {
      const localPath = getLocalPath(x.payload, routeMap, product.key);
      if (localPath) {
        navigate("/" + localPath);
      } else {
        console.error('localPath not found: ' + x.payload);
      }
    });

    eventHub.subscribe("title", (x) => {
      document.title = x.payload;
    });

    eventHub.subscribe("breadcrumb", (x) => {
      window.dispatchEvent(
        new CustomEvent("breadcrumb", {
          bubbles: true,
          detail: x.payload,
        })
      );
    });

    eventHub.subscribe("notification", (x) =>
      handleNotification(x.payload.message, x.payload.type)
    );

    eventHub.subscribe("error", (x) =>
      handleNotification(x.payload.message, x.payload.type)
    );

    eventHub.subscribe("redirecting", (x) =>
      //setRedirectTimer()
      console.log("redirecting triggered")
    );

    eventHub.subscribe("backdrop.on", (x) => {
      //showModalD();

      setmodalDetails([
        {
          id: x.payload.message,
          title: x.payload.message,
          size: "small",
          actions: { act },
          onClose: { hideModalD },
        },
      ]);
      console.log("backdrop.on triggered");
    });

    eventHub.subscribe("backdrop.off", (x) => {
      hideModalD();
      console.log("backdrop.off triggered");
    });

    eventHub.subscribe("wait.on", (x) => {
      setshowLoader(true);
      setloaderDetails([
        {
          id: x?.payload?.message,
          subtle: x?.payload?.subtle || false,
        },
      ]);
      setTimeout(() => {
        setshowLoader(false)
      }, 30000);
    });

    eventHub.subscribe("wait.off", (x) => {
      setshowLoader(false);
    });

    eventHub.subscribe("session", (x) => {
      const previousSession = sessionStorage[product.key] || {};
      sessionStorage[product.key] = Object.assign(previousSession, x.payload);
    });

    eventHub.subscribe("permissions", (x) => {
      const permissionSet = parsePermissionResponse(product.key, x.payload)
      const permissionData: PermissionSet = JSON.parse(JSON.stringify(permission));
      permissionData[product.key] = permissionSet;
      dispatch(setPermission(permissionData));
    });

    eventHub.subscribe("multi-tenant-selection", (x) => {
      onMultiTenantSelection(x.payload)
    });

    eventHub.subscribe(
      "locales",
      (x) => console.log("locales triggered")
      //localeService.setLocales(x.payload)
    );

    eventHub.subscribe("active", () => {
      const userActive = new CustomEvent('isUserActive', {
        detail: {
          isActive: true
        }
      });
      window.dispatchEvent(userActive);
    });

    eventHub.subscribe("unauthorise", (x) => {
      navigate("/unauthorise", { replace: true, state: { title: x.payload } });
    });
  }
  function onMultiTenantSelection(selection: any) {
    const settings: any = window.sessionStorage.getItem(
      "multi-tenant-selection"
    ) ? JSON.parse(window.sessionStorage.getItem(
      "multi-tenant-selection"
    )) : {}
    settings[product.key] = selection;
    window.sessionStorage.setItem('multi-tenant-selection', JSON.stringify(settings));
  }
  function handleNotification(
    message: string,
    notificationType?: "info" | "success" | "warning" | "error" | "danger"
  ) {
    if (notificationType == "danger") {
      notificationType = "error";
    }
    showNotificationSnackBar();
    //showNotificationHandler(true)
    setTimeout(hideNotification, 5000);
    setnotificationDetails([
      {
        title: message,
        subTitle: '',
        type: notificationType,
        onClose: { hideNotification },
      },
    ]);
  }
  function checkForRedirect(url: string) {
    if (url != unsafeSrc) {
      if (unionConfig.debugMode) {
        console.log("Redirect detected:", url);
      }
      const pathRelativeToBase = PathHelper.getRelativePath(url, product.environment.appRootUrl);
      const localPath = getLocalPath(pathRelativeToBase, routeMap, product.key);
      if (localPath !== undefined) {
        navigate("/" + localPath);
      } else {
        console.error("Unrecognized: " + pathRelativeToBase);
      }
    }
  }

  return (
    <>
      {notificationDetails.map((lst) => (
        <>
          {showNotificationBar && (
            <UnionNotification
              title={lst.title}
              subTitle={lst.subTitle}
              type={lst.type}
              onClose={hideNotification}
            ></UnionNotification>
          )}
        </>
      ))}
      {loaderDetails.map((lst) => (
        <>
          {showLoader && (
            <UnionLoader subtle={lst.subtle} id={lst.id}></UnionLoader>
          )}
        </>
      ))}
      {modalDetails.map((lst) => (
        <div id="childcontainer">
          {showModal && (
            <UnionModal
              id={lst.id}
              title="Modal example"
              size="large"
              actions={act}
              onClose={hideModalD}
              actionsLayout={"start"}
            //links={links}
            ></UnionModal>
          )}
        </div>
      ))}
      <iframe
        src={src}
        key={src}
        id="appFrame"
        width="100%"
        height="100%"
        name="appFrame"
        title="appFrame"
        referrerPolicy="origin"
        sandbox="allow-scripts allow-same-origin allow-forms allow-top-navigation allow-modals allow-downloads allow-popups"
        onLoad={onIFrameLoaded}
      />
    </>
  );
}
