import { Menu, MenuItem, ProductRouteData, UnionConfig } from "models";
import { PathHelper } from "services/path-helper";
import { getRouteByLocalPath } from "services/router-helper";
import { setMenu } from "redux/features/menu";
import axios from "axios";
import store from "store";

export async function loadMenu(unionConfig: UnionConfig): Promise<any> {
    let menu: any[] = [];
    if (unionConfig.manifest) {
        unionConfig.manifest.forEach(async product => {
            const url = PathHelper.isAbsolutePath(product.environment.apiEndpoints.menu)
                ? product.environment.apiEndpoints.menu
                : (product.environment.apiRootUrl || product.environment.appRootUrl) + '/' + product.environment.apiEndpoints.menu;

            try {
                const response: MenuItem[] = await getMenu(url, product.isInternal);
                if (response) {
                    parseMenu(response, product.key)
                    const topLevel: MenuItem = {
                        productKey: product.key,
                        label: product.name,
                        iconClass: product.icon,
                        items: applyRoutingRules(response)
                    };
                    const menus = [...menu, topLevel];
                    const names = unionConfig.manifest.map((p) => p.name);
                    menus.sort((a: { label: any; }, b: { label: any; }) => names.indexOf(a.label) < names.indexOf(b.label) ? -1 : 1)
                    menu = menus;
                    store.dispatch(setMenu(menu));
                }
            }
            catch (error) {
                console.log(error);
            }
        })
        return menu;
    }
}

function getMenu(url: string, isInternal = false) {
    let timeoutRequest: number = null;
    return new Promise<MenuItem[]>((resolve, reject) => {
        if (isInternal) {
            timeoutRequest = 3000;
        }
        axios.get<Menu>(url, { timeout: timeoutRequest })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

function parseMenu(menu: Menu, productKey: string) {
    menu.forEach(menuItem => {
        menuItem.productKey = productKey;
        menuItem.abbreviation = menuItem.abbreviation || getAbbreviation(menuItem.label);

        if (menuItem.path !== undefined) {
            menuItem.path = `/${productKey}/${menuItem.path}`.replace('//', '/');
        }

        if (menuItem.items) {
            parseMenu(menuItem.items, productKey);
        }
    });
}

function getAbbreviation(label: string): string {
    const letters = label.replace(/\/\\&\\-/g, ' ').match(/(?:^|[\s])([A-z])/g).map(x => x.replace(' ', ''))
    return letters.length > 1
        ? letters[0] + letters[letters.length - 1]
        : letters[0];
}

function applyRoutingRules(menu: Menu): Menu {
    const results: Menu = [];

    const routeMap = store.getState().route.routeMap;
    const unionConfig = store.getState().unionConfig.unionConfig;

    menu.forEach(item => {
        if (item.items) {
            item = Object.assign({}, item);
            item.items = applyRoutingRules(item.items);
        }

        if (item.path) {
            const route = getRouteByLocalPath<ProductRouteData>(item.path, routeMap);
            if (!route) {
                if (unionConfig.debugMode) {
                    console.log("Unwired route: ", item.path);
                }
                return;
            }

            if (route) {
                if (route.permissions !== undefined && item.permissions === undefined) {
                    item.permissions = route.permissions;
                }

                if (route.permissionRequirement !== undefined && item.permissionRequirement === undefined) {
                    item.permissionRequirement = route.permissionRequirement;
                }

                if (route.popout !== undefined && item.popout === undefined) {
                    item.popout = route.popout;
                }
            }
        }
        results.push(item);
    });

    return results;
}
