import { LastVisitedPage } from "models/last-visited-page/last-visited-page";
import { insertLastVisitedPage } from "./lastvisitedpage.service";

export function lastVisitedPage(prod: any, unionConfig: any, pathname: any) {
  if (pathname) {
    let navpath = window.location.origin;
    if (navpath.includes("localhost")) {
      navpath = "https://reactui.dev.finapps.ihsmarkit.com" + pathname;
    } else {
      navpath = navpath + pathname;
    }

    const lastVisitedPage: LastVisitedPage = {
      applicationName: prod.key,
      visitedDateTime: formatDate(new Date()),
      visitedURL: navpath,
    };
    insertLastVisitedPage(unionConfig, lastVisitedPage);
  }
}

function padTo2Digits(num: any) {
  return num.toString().padStart(2, "0");
}

function formatDate(date: any) {
  const year = date.getFullYear();
  const month = padTo2Digits(date.getUTCMonth() + 1);
  const day = padTo2Digits(date.getUTCDate());
  const onlydate = [year, month, day].join("/");

  const hr = padTo2Digits(date.getUTCHours());
  const min = padTo2Digits(date.getUTCMinutes());
  const sec = padTo2Digits(date.getUTCSeconds());

  const onlytime = [hr, min, sec].join(":");

  return onlydate + " " + onlytime;
}
