import { useEffect, useRef, useState } from "react";
import { UnionConfig, UserIdleConfig } from "models/union-config";
import { Manifest, Product } from "models/product";
import { setunionConfig } from "redux/features/unionconfig";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setVersions } from "redux/features/versions";
import { loadMenu } from "services/menu-builder/menu-builder.service";
import { useIdleTimer, IIdleTimer } from "react-idle-timer";
import { Timeout } from "components/timeout";
import { getVersions } from "services/version-helper/version-helper.service";
import { SessionStorageService } from "services/storage-service";
import { AppRoutes } from "router/routes";
import { load } from "services/user/user.service";
import { loadPermission } from "services/permission/permission.service";
import { setPermission } from "redux/features/permission";
import { loadProductRoutes } from "router/routes/router.services";
import { interceptor } from "services/interceptor";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { UnionLoader } from "components/shared/loader";
import "./app.css";
import "@fortawesome/fontawesome-pro/css/solid.css";

library.add(fas);

export function App() {
  let idleTimer = null as IIdleTimer;
  const userTimeoutConfig = useRef({} as UserIdleConfig);
  const [isIdle, setIsIdle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayTimeoutModal, setDisplayTimeoutModal] = useState(false);
  const dispatch = useAppDispatch();
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  // Need to fix in future
  const timer = useRef(1200000);

  useEffect(() => {
    const UnionConfigLoader = async () => {
      let unionConfig = new UnionConfig();
      fetch(
        window.location.origin +
        "/assets/union-config/" +
        window.location.hostname +
        ".json"
      ).then((response) => {
        response.json().then(async (configValue: UnionConfig) => {
          unionConfig = configValue;
          await fetch(
            window.location.origin +
            "/assets/union-config/manifest/" + unionConfig.manifest
          ).then(async (res) => {
            await res.json().then((value: Manifest) => {
              unionConfig.manifest = value;
              if (unionConfig.sandboxEnabled && localStorage.manifest) {
                unionConfig.manifest = JSON.parse(localStorage.manifest);
              }
              const environments = JSON.parse(
                localStorage.getItem("environments") || "{}"
              );
              unionConfig.manifest.forEach((x: Product) => {
                if (!x.environment) {
                  if (
                    environments[x.key] &&
                    x.environments[environments[x.key]]
                  ) {
                    x.environment = x.environments[environments[x.key]];
                  } else if (
                    unionConfig.defaultEnvironment &&
                    x.environments[unionConfig.defaultEnvironment]
                  ) {
                    x.environment =
                      x.environments[unionConfig.defaultEnvironment];
                  } else {
                    x.environment =
                      x.environments[Object.keys(x.environments).reverse()[0]];
                  }
                } else {
                  if (
                    environments[x.key] &&
                    x.environment.name.toLocaleLowerCase() !==
                    environments[x.key]
                  ) {
                    x.environment = x.environments[environments[x.key]];
                  }
                }
              });
              if (unionConfig.sandboxEnabled && localStorage.manifest) {
                localStorage.setItem(
                  "manifest",
                  JSON.stringify(unionConfig.manifest)
                );
              }
              dispatch(setunionConfig(unionConfig));
            });
          });
        });
      });
    };
    load();
    UnionConfigLoader();
  }, []);

  useEffect(() => {
    if (unionConfig.manifest) {
      interceptor(unionConfig);
      const loadRoute = async () => {
        await loadProductRoutes(unionConfig);

        const permission = await loadPermission(unionConfig);
        dispatch(setPermission(permission));

        await loadMenu(unionConfig);

        setLoading(false);
      }
      loadRoute();
      getVersions(unionConfig)
        .then((versions) => {
          dispatch(setVersions(versions));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [unionConfig]);

  useEffect(() => {
    if (unionConfig.userIdleTimeoutInSeconds) {
      //Fetching config from sessionStorage for Automation Testing
      const configValues: UserIdleConfig = SessionStorageService.getItem(
        "sessionTimeoutConfig",
        "sessionTimeoutConfig"
      );
      if (configValues.timeout && configValues.idle) {
        userTimeoutConfig.current.idle = configValues.idle;
        userTimeoutConfig.current.timeout = configValues.timeout;
      } else {
        userTimeoutConfig.current.idle = unionConfig.userIdleTimeoutInSeconds.idle;
        userTimeoutConfig.current.timeout =
          unionConfig.userIdleTimeoutInSeconds.timeout;
      }

      //setting autoLogoutTime values
      const timeoutMinutes = userTimeoutConfig.current.idle + userTimeoutConfig.current.timeout;
      SessionStorageService.setItem('autoLogoutTime', JSON.stringify(new Date(new Date().valueOf() + (timeoutMinutes * 1000))), 'autoLogoutTime');

      timer.current = userTimeoutConfig.current.idle * 1000;
      setTimeout(() => {
        idleTimer.start();
      });
    }
  }, [unionConfig.userIdleTimeoutInSeconds]);

  window.addEventListener('isUserActive', () => {
    onAction();
  });

  const onIdle = () => {
    if (!isIdle) {
      setIsIdle(true);
      setDisplayTimeoutModal(true);
    }
  };

  const onAction = () => {
    if (!displayTimeoutModal) {
      //setting autoLogoutTime values
      const timeoutMinutes = userTimeoutConfig.current.idle + userTimeoutConfig.current.timeout;
      SessionStorageService.setItem('autoLogoutTime', JSON.stringify(new Date(new Date().valueOf() + (timeoutMinutes * 1000))), 'autoLogoutTime');
      setIsIdle(false);
      idleTimer.reset();
      idleTimer.start();
    }
  };

  idleTimer = useIdleTimer({
    timeout: timer.current,
    onIdle,
    onAction,
    startManually: true,
  });

  const handleContinueSession = () => {
    idleTimer.reset();
    setDisplayTimeoutModal(false);
    setIsIdle(false);
    idleTimer.start();
  };

  return (
    <>
      {loading ? <UnionLoader /> : <AppRoutes />}
      {displayTimeoutModal && (
        <Timeout
          showModalHandler={handleContinueSession}
          id="user-idle-modal"
        />
      )}
    </>
  );
}
