import axios from "axios";
import { AppViewer } from "components/app-viewer";
import { Layout } from "components/layout";
import { AppRoute, Product, ProductRouteData, RouteMap, UnionConfig } from "models";
import { RouteObject } from "react-router-dom";
import { setRoute } from "redux/features/routes";
import { PathHelper } from "services/path-helper";
import { getPathMatchRegex } from "services/router-helper";
import { ProtectedRoute } from "./protectedroute";
import store from "store";

export async function loadProductRoutes(unionConfig: UnionConfig): Promise<any> {
    let routeMap: RouteMap[] = [];
    let route: RouteObject[] = [];
    if (unionConfig.manifest) {
        // for (const product of unionConfig.manifest) {
        unionConfig.manifest.forEach(async product => {
            const url = PathHelper.isAbsolutePath(
                product.environment.apiEndpoints.routes
            )
                ? product.environment.apiEndpoints.routes
                : (product.environment.apiRootUrl ||
                    product.environment.appRootUrl) +
                "/" +
                product.environment.apiEndpoints.routes;

            try {
                const routeData = await getRoutes(url, product.isInternal);
                const data = parseRouting(product, routeData);
                if (data) {
                    route = [...route, data.parentRoute]
                    routeMap = data.routeMap
                }
                store.dispatch(setRoute({
                    routeMap: routeMap,
                    routes: route
                }))
            }
            catch (error) {
                // loadFailures.push({ product, error });
                console.error(error);
            }
        })
    }
    return { route, routeMap };
}

function getRoutes(url: string, isInternal = false) {
    let timeoutRequest: number = null;
    return new Promise((resolve, reject) => {
        if (isInternal) {
            timeoutRequest = 3000;
        }
        axios.get(url, { timeout: timeoutRequest })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
    })
}

function parseRouting(
    product: Product,
    config: any
): any {
    const parentRoute: AppRoute<ProductRouteData> = {
        path: product.key,
        element: <Layout />,
        children: [],
    };

    config.forEach(
        (route: {
            path: string;
            title: any;
            contentPath: any;
            queryMap: any;
            permissions: any;
            permissionRequirement: any;
            popout: any;
            defaultRoute: any;
        }) => {
            try {
                parentRoute.children.push({
                    path: route.path,
                    element: (
                        <ProtectedRoute><AppViewer
                            props={{
                                title: route.title || nameRoute(route.path),
                                productKey: product.key,
                                contentPath: route.contentPath,
                                queryMap: route.queryMap,
                                permissions: route.permissions,
                                permissionRequirement: route.permissionRequirement,
                                popout: route.popout,
                                defaultRoute:
                                    route.defaultRoute && !route.path.match(/(?:^|\/)\\:/),
                            }}
                        /></ProtectedRoute>
                    ),
                });

                const localPath = product.key + '/' + [route.path].filter(x => x).join('/');

                routeMap = [
                    ...routeMap,
                    {
                        productKey: product.key,
                        productName: (product.name === undefined) ? product.key : product.name,
                        path: "/" + product.key + "/" + route.path,
                        title: route.title || nameRoute(route.path),
                        contentPathDefinition: route.contentPath,
                        contentPathRegex: getPathMatchRegex(route.contentPath),
                        contentPath: route.contentPath,
                        localPathDefinition: localPath,
                        localPathRegex: getPathMatchRegex(localPath),
                        queryMap: route.queryMap,
                        permissions: route.permissions,
                        permissionRequirement: route.permissionRequirement,
                        popout: route.popout,
                        defaultRoute:
                            route.defaultRoute && !route.path.match(/(?:^|\/)\\:/),
                    }];
            } catch (error) {
                console.error(error);
            }
        }
    );
    return ({ parentRoute, routeMap });
}

const nameRoute = (path: string) => {
    return path
        .split("/")
        .reverse()[0]
        .replace(/\\-\\_/g, " ")
        .replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        );
}

let routeMap: RouteMap[] = [];
