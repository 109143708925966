import { getAuthSettings } from "auth-settings";
import { User as OidcUser, UserManager } from "oidc-client";
import { SessionStorageService } from "services/storage-service";
import { setUserAuth } from "redux/features/auth";
import store from "store";
import CryptoES from "crypto-es";

export function getUser(): OidcUser {
  const userInfo: OidcUser = SessionStorageService.getItem(
    "user-info",
    "user-info"
  );
  return userInfo;
}

export function revoke() {
  // this.httpClient.get(this.unionConfig.apiUrl + "/revoke").subscribe()
}

export function logout() {
  console.log("In Logout Function");
}

export function timeout() {
  console.log("In Timeout Function");
}

export function load() {
  const oidcUser: OidcUser = SessionStorageService.getItem(
    "user-info",
    "user-info"
  );
  HandleUpdate(oidcUser);
  setInterval(() => updateUser(), 1200000);
}

export function updateUser() {
  getAuthSettings().then((settings) => {
    const userInfo: OidcUser = SessionStorageService.getItem(
      "user-info",
      "user-info"
    );
    const userManager = new UserManager(settings);
    userManager
      .signinSilent({ refresh_token: userInfo.refresh_token })
      .then((oidcUser) => {
        SessionStorageService.setItem(
          "user-info",
          JSON.stringify(oidcUser),
          "user-info"
        );
        SessionStorageService.setItem(
          "accessToken",
          JSON.stringify(oidcUser.access_token),
          "union-access-token"
        );
        Object.keys(sessionStorage)
          .filter((x) => x.startsWith("oidc."))
          .forEach((x) => sessionStorage.removeItem(x));
        HandleUpdate(oidcUser);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}

function HandleUpdate(oidcUser: OidcUser) {
  // const _user = {
  //   userId: oidcUser.profile.auditTrackingId,
  //   name: oidcUser.profile.email,
  //   fullName: oidcUser.profile.name,
  //   emailAddress: oidcUser.profile.email,
  //   lastLogin: new Date(),
  // };

  const _authToken = {
    value: oidcUser.access_token,
    type: oidcUser.token_type,
    expires: oidcUser.expires_at,
  };
  const oidUser = JSON.stringify(oidcUser);

  const oidUserToken = JSON.stringify(oidcUser.access_token);

  store.dispatch(
    setUserAuth({
      accessToken: CryptoES.AES.encrypt(oidUserToken, "union-access-token"),
      "user-info": CryptoES.AES.encrypt(oidUser, "user-info"),
      authToken: _authToken,
    })
  );
}
