import { Notification } from "@medm-ui/notification";
import { NotificationGroup } from "@progress/kendo-react-notification";
import "./notification.css";

export function UnionNotification(props: any) {
  const notificationtype = props.type;
  return (
    <div className="notification">
      <NotificationGroup {...{
        style: {
          alignItems: "flex-end",
          bottom: 0,
          right: 0,
        }
      }}>
        <Notification closable={true} onClose={props.onClose} toast type={{ icon: true, style: notificationtype }} >
          <b>{props.title}</b>
          <div>{props.subTitle}</div>
        </Notification>
      </NotificationGroup>
    </div >
  );
}
