import { Footer } from "@medm-ui/footer";
import "./footer.css";

export function UnionFooter() {
  const NavLink = [
    {
      displayName: "Modern Slavery Act Statement",
      url: "https://ihsmarkit.com/Legal/modern-slavery-statement.html",
    },
    {
      displayName: "Privacy Policy",
      url: "https://ihsmarkit.com/Legal/privacy.html",
    },
    {
      displayName: "Terms of Use",
      url: "https://www.spglobal.com/en/terms-of-use",
    },
    {
      displayName: "Disclaimers",
      url: "https://www.spglobal.com/en/terms-of-use",
    },
  ];
  const style = {
    height: "50px",
  };
  return (
    <>
      <Footer
        id="footer"
        copyrightText="©$YEAR S&P Global. All rights reserved."
        linksList={NavLink}
        style={style}
      ></Footer>
    </>
  );
}
